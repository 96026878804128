/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify';
import pinia from '../store';
import router from '../router';
import v18n from './v18n';
import vPhoneInput from './vPhoneInput';
import { Vuetify3Dialog } from 'vuetify3-dialog';
// import { VueFire, VueFireAuth } from "vuefire";
// import { firebaseApp } from "./firebase";

// Types
import type { App } from 'vue';

export function registerPlugins(app: App) {
  app
    .use(vuetify)
    .use(vPhoneInput)
    .use(router)
    .use(v18n)
    .use(Vuetify3Dialog, {
      vuetify,
    })
    .use(pinia);
}
