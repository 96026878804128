/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
// import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure your project is capable of handling css files
import 'vuetify/styles';
import { md3 } from 'vuetify/blueprints';

// Composables
import { createVuetify } from 'vuetify';
import colors from 'vuetify/util/colors';

import { de, fr } from 'vuetify/locale';

import DownloadLoopIcon from '@/customIcons/DownloadLoopIcon.vue';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  locale: {
    locale: 'de',
    messages: { de, fr },
  },
  icons: {
    defaultSet: 'mdi',
    aliases: {
      edit: 'mdi-pencil-outline',
      delete: 'mdi-delete-outline',
      filter: 'mdi-filter-outline',
      dragVertical: 'mdi-swap-vertical',
      downloadLoop: DownloadLoopIcon,
    },
  },
  blueprint: md3,
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#424242',
          secondary: '#55aa70',
          error: '#b71c1c',
        },
      },
    },
  },
});
