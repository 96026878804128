import 'flag-icons/css/flag-icons.min.css';
import 'v-phone-input/dist/v-phone-input.css';
import { createVPhoneInput } from 'v-phone-input';
import i18n from './v18n';

export default createVPhoneInput({
  countryIconMode: 'svg',
  defaultCountry: 'CH',
  preferCountries: ['CH', 'DE', 'FR', 'IT', 'AT'],
  invalidMessage: () => {
    return i18n.global.t('validation.invalid-phone');
  },
  label: () => i18n.global.t('common.phone-number'),
  countryLabel: () => i18n.global.t('common.country'),
});
